<template>
  <AMenu class="q-header-notifications">
    <div class="q-header-notifications__content">
      <ASpin
        v-if="isLoading"
        class="q-header-notifications__content-loading"
      />
      <AEmpty
        v-else-if="!appeals?.length"
        class="q-header-notifications__content-empty"
      />
      <template v-else>
        <AMenuItem
          v-for="appeal in appeals"
          :key="appeal.id"
          @click="goTo(appeal.id)"
        >
          <div class="q-header-notifications__content-item">
            <div class="q-header-notifications__content-item-icon">
              <MailOutlined />
            </div>
            <div class="q-header-notifications__content-item-content">
              <span class="q-header-notifications__content-item-content-topic">
                {{ appeal.topic }}
              </span>
              <span class="q-header-notifications__content-item-content-description">
                {{ appeal.created }} {{ appeal.content }}
              </span>
            </div>
          </div>
        </AMenuItem>
      </template>
    </div>
    <AMenuItem @click="exit">
      <div class="q-header-notifications__button">
        <LogoutOutlined />
        Выйти из аккаунта
      </div>
    </AMenuItem>
  </AMenu>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { LogoutOutlined, MailOutlined } from '@ant-design/icons-vue'
import {
  Empty as AEmpty,
  Menu as AMenu,
  MenuItem as AMenuItem,
  Spin as ASpin,
  message,
} from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import type { IAppealsData } from '@/views/residential/appeals/help/interfaces'
import CommunicationService from '@/services/main/CommunicationService'
import useLogout from '@/сomposables/logout'

const route = useRoute()
const router = useRouter()
const exit = useLogout()

const isLoading = ref(false)
const appeals = ref<IAppealsData[]>([])

function goTo(id?: number) {
  if (id) {
    router.push({ path: `/resident/${route.params?.userId}/chats/${id}` })
  }
  else {
    router.push({ path: `/resident/${route.params?.userId}/appeals` })
  }
}

async function initAppeals() {
  isLoading.value = true
  try {
    const { data } = await CommunicationService.getAppeals()
    appeals.value = data?.data || []
  }
  catch (err: any) {
    message.error({ content: err?.response?.data?.details })
  }
  finally {
    isLoading.value = false
  }
}

onMounted(() => initAppeals())
</script>

<style lang="sass" scoped>
@import './QHeaderNotifications'
</style>
