<template>
  <nav class="q-navbar">
    <div class="q-navbar__logo">
      <Icon>
        <template #component>
          <component :is="QIconLogo" />
        </template>
      </Icon>
    </div>
    <ul class="q-navbar__menu">
      <QNavbarItem :item="item" v-for="item in items" :key="item.name" />
      <QNavbarMore v-if="moreItems.length" :items="moreItems" />
    </ul>
    <ul class="q-navbar__exit">
      <QNavbarExit />
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import Icon from '@ant-design/icons-vue'
import type { INavbarItem } from './interfaces'
import QIconLogo from '@/icons/QIconLogo.vue'

import QNavbarItem from '@/components/navbar/QNavbarItem.vue'
import QNavbarExit from '@/components/navbar/QNavbarExit.vue'
import QNavbarMore from '@/components/navbar/QNavbarMore.vue'
import { useShowByServer } from '@/сomposables/useShowByServer'

const { show } = useShowByServer()

const router = useRouter()

const items = ref<INavbarItem[]>([])
const moreItems = ref<INavbarItem[]>([])
const screenWidth = ref(window.innerWidth)

function findSeparateIndex(arrayLength: number) {
  let index = arrayLength
  // ширина одной ячейки меню
  const step = 60
  const maxWidth = arrayLength * step

  if (screenWidth.value < maxWidth) {
    for (let i = 1; i < arrayLength; i++) {
      if (screenWidth.value >= i * step) {
        index = i - 1
      }
    }
  }

  return index
}

function initData() {
  const itemList: INavbarItem[] = []
  router.options?.routes?.forEach((route) => {
    if (route.meta?.menu === 'main') {
      if (route.name === 'Оплата услуг') {
        if (show('autopay')) {
          itemList.push({
            ...route,
            name: route.name,
            path: route.path,
            icon: route.meta?.icon,
          })
        }
      }
      else {
        itemList.push({
          ...route,
          name: route.name,
          path: route.path,
          icon: route.meta?.icon,
        })
      }
    }
  })

  const endIndex = findSeparateIndex(itemList.length)

  items.value = itemList.slice(0, endIndex)
  moreItems.value = itemList.slice(endIndex, itemList.length)
}

function updateScreenWidth() {
  screenWidth.value = window.innerWidth
  initData()
}

// Следить за размером экрана
function onScreenResize() {
  window.addEventListener('resize', () => updateScreenWidth())
}

onMounted(() => {
  initData()
  onScreenResize()
})

onUnmounted(() => window.removeEventListener('resize', () => updateScreenWidth()))
</script>

<style lang="sass" scoped>
@import './QNavbar'
</style>
